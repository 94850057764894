.branch{
    background: #010231;
}

.branch-cont{
    padding: 2.5em;
    display: flex;
    flex-wrap: wrap;
}

.branch-cont button{
    margin: 0.7em;
    padding: 0.5em;
    background: #fff;
    width: 100%; 
    border: none;
    transition: all 0.2s;
}

.branch-cont button:hover{
    transform: scale(1.08);
}

.branch-cont button img{
    width: 100%;
}

@media(min-width:768px){
    .branch-cont button{
        width: 46.37%;
    }
}

@media(min-width:992px){
    .branch-cont button{
        width: 30.69%;
    }
}


@media(min-width:1200px){
    .branch-cont button{
        width: 22.84%;
    }
}

@media(min-width:1400px){
    .branch-cont button{
        width: 23.16%;
    }
}