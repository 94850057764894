/*Home Components*/
.card-t h2, .card-t h3, .cont-name-tp h2, /* card */
.about-t h3, .about-t h2, .about-t span, /* about section */
.fr-cont-title, .fr-cont-title .title-navy, .fr-title, /* franchise section - Components */
.fee-main-title, .cent-h2, .cent-h3, .fifty-cont h5, /* fee section */
.newsevent-title, .latest-title, /* news and event section */
/*End Home Components*/
/*About Components*/
.info-title, .info-span, /* About Us Section*/
.company-title, .company-title span, /* Company Section */
.franchise-title, /* Franchise Section */
.criteria-title, /* Criteria Section */
.success-title, /* Success Section */
.growth-title, /* Growth Section */
.blessed-title, /* Blessed Section */
.mv-title, /* Mission and Vision Section */
/*End About Components */
/* Testimonials Section */
.testimonials-title, .testimonials-title span, .list-test h3,
/* End Testimonials Section */
/* Branches Section */
.box-cont-marker h2, .box-contact h5, .box-email h5, .branches-title h2, .branches-title h1, .branches-title span,
/*Branch Section*/
/*Modal Title*/
.modal-title, .modal-divider p, .modal-divider span,
/*End Modal Title*/
/* Franchising Title */
.fr-main-h2, .fr3rd-title,
/*Email Title*/
.email-h2, .map-box h5,
/*FR Prog*/
.fr-link, .frprog-title, .frprog-subtitle,
/* Team Page */
.main-team-title, .main-team-title-2, .other-post-cont h3,
/* End Team Page */
/* Franchise Expo Indo */
.expo-title, .expo-span,
/* End Franchise Expo indo*/
/* No Match */
.no-match-box h1, .no-match-box h2, .no-match-box h3
{
    font-family: 'Roboto', sans-serif;
}

.card-t, .about-t{
    text-align: center;
    margin-bottom: 1.5em;
}

.card-t h2, .about-t h2, .fr-cont-title, .fee-main-title, .newsevent-title, .info-title,
.company-title, .franchise-title, .criteria-title, .success-title, .growth-title, 
.blessed-title, .fr-main-h2, .email-h2, .map-box h5, .frprog-title, .main-team-title-2,
.expo-title{
    font-weight: 900;
    font-size: 2em;
}

.card-t h3, .about-t h3, .info-title-h3, .fr-main-h3, .email-h3{
    font-weight: 300;
    font-style: italic;
    font-size: 1.3em;
}

.card-cont-pg{
    padding: 2em 3em;
}

.container{
    width: 96%;
    margin: 0 auto;
}

/* testimonials page */
.testimonials-title{
    font-weight: 900;
    font-size: 3em;
    margin-top: 0.5em;
    text-align: center;
    color: #900018;
}
/* end testimonials */

.testimonials-title span, .branches-title span, .branches-title h2 {
    color: #000231;
}

.justify-p{
    text-align:justify;
}

@media(min-width:576px){
    .container{
        max-width: 540px;
    }
}

@media(min-width:768px){
    .container{
        max-width: 720px;
    }
}

@media(min-width:992px){
    .container{
        max-width: 960px;
    }
}

@media(min-width:1200px){
    .container{
        max-width: 1140px;
    }
}

@media(min-width:1400px){
    .container{
        max-width: 1320px;
    }
}
