input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.email-cont-title{
    text-align: center;
    padding: 0 0 1em 0;
}

.email-cont{
    padding: 1.5em 0 5em;
}

.email-h2{
    color: #900018;
}

.email-p{
    font-size: 0.9em;
   color: #900018b9;
}

label{
    color: #0102319f;
    font-size: 1.1em;
    font-weight: 500;
}

textarea{
    height: 150px;
}

.email-btn{
    background-color: #900018!important;
    border-color: #900018!important;
}

.email-btn:hover{
    background-color: #c90021!important;
    border-color: #c90021!important;
}

.swal2-title{
    font-size: 1.5em!important;
    font-family: 'Roboto', sans-serif;
    color: #010231!important;
}

.swal2-styled.swal2-confirm{
    background-color: #010231!important;
    font-size: 0.9em!important;
}