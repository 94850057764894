.top-btn{
    position: fixed;
    background: rgba(255, 255, 255, 0);
    padding: 0;
    right: 10px;
    bottom: 10px;
    border: none;
    font-size: 2.5rem;
}

.chev-up{
    background: #C0000A;
    border-radius: 20px;
    color: #fff;
    transition: all 0.3s
}

.chev-up:hover{
    background: #262885;
}