.frprog-cont{
    margin-bottom: 2em;
}

.frprog-img{
    width: 100%;
    object-fit: cover;
    height: 275px;
}

.frprog-box{
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.frprog-title{
    color: #900018;
    margin: 1em 0 0.5em;
    font-size: 2.5em;
    letter-spacing: 0.2em;
    text-align: center;
}

.frprog-subtitle{
    font-size: 1em;
    font-weight: 700;
    margin: 0.5em 0 0.8em;
    color: #010231;
}

@media(min-width:576px){
    .frprog-img{
        height: 335px;
    }
}

@media(min-width:768px){
    .frprog-img{
        height: 445px;
    }
}

@media(min-width:992px){
    .frprog-cont{
        display: flex;
        justify-content: space-between;
    }

    .frprog-title{
        text-align: start;
    }

    .frprog-cont-info{
        width: 100%;
        max-width: 610px;
        padding-top: 2em;
    }

    .frprog-cont-box{
        display: flex;
        flex-wrap: wrap;
        justify-content:space-around;
    }

    .frprog-box{
        width: 45%;
    }

    .frprog-img{
        width: 100%;
        object-fit: cover;
        height: 200px;
    }
}

@media(min-width: 1200px) {
    .frprog-cont-info{
        max-width: 790px;
    }

    .frprog-img{
        width: 100%;
        object-fit: cover;
        height: 300px;
    }
}

@media(min-width: 1400px) {
    .frprog-cont-info{
        max-width: 970px;
    }
}
