.contact-cont{
    background: #900018;
}

.contact-box{
    width: 50%;
    min-width: 240px;
    padding: 2em 1em 1.2em;
    margin: 0 auto;
    color: #fff;
}

.contact-box span{
    font-weight: 700;
}

@media(min-width: 768px){
    .contact-flex{
        display: flex;
        justify-content: center;
    }
}

@media(min-width: 992px){
    .contact-box{
        padding-left: 3em;
    }
}

@media(min-width: 1200px){
    .contact-box{
        padding-left: 5em;
    }
}