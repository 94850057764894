.no-match-cont{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.596), rgba(0, 0, 0, 0.5)), url(../img/timeless-industry.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    object-fit: cover;
    background-position: center;
    height:100vh;
}

.no-match-cont div{
    width: 100%;
    margin: 0 auto;
    padding: 8em 2em 0;
}

.no-match-box h1, .no-match-box h2, .no-match-box h3{
    text-align: center;
    font-weight: 900;
}

.no-match-box h1{
    color: #C0000A;
    font-size: 5em;
}

.no-match-box h2, .no-match-box h3{
    color: #fff;
}

.no-match-box h2{
    font-size: 1.3em;
}

.no-match-box h3{
    font-size: 0.9em;
}

.btn-cont{
    transform: translateY(-70px);
}

.no-match-box a{
    margin-top: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #C0000A;
    width: 150px;
    margin: 1.5em auto 0;
    border-radius: 20px;
    padding: 0.4em 0;
    color: #fff!important;
    font-family: 'Roboto', sans-serif;
}

.no-match-box a:hover{
    background-color: #fff;
    color: #C0000A!important;
}

@media(min-width: 576px){
    .no-match-box h1{
        font-size: 8em;
    }

    .no-match-box h2{
        font-size: 1.5em;
    }
    
    .no-match-box h3{
        font-size: 1em;
    }
}

@media(min-width:768px){
    .no-match-box h1{
        font-size: 10em;
    }

    .no-match-box h2{
        font-size: 2em;
    }
    
    .no-match-box h3{
        font-size: 1.1em;
    }

    .no-match-box a{
        font-size: 1.2em;
        width: 180px;
    }
}

@media(min-width:992px){
    .no-match-cont div{
        width: 100%;
        margin: 0 auto;
        padding: 5em 2em 0;
    }
    

    .no-match-box h1{
        font-size: 12em;
    }

    .no-match-box h2{
        font-size: 3em;
    }
    
    .no-match-box h3{
        font-size: 1.5em;
    }

    .no-match-box a{
        font-size: 1.2em;
        width: 180px;
    }
}

@media(min-width:1400px){
    .no-match-cont div{
        width: 100%;
        margin: 0 auto;
        padding: 10em 2em 0;
    }
    

    .no-match-box h1{
        font-size: 15em;
    }

    .no-match-box h2{
        font-size: 4em;
    }
    
    .no-match-box h3{
        font-size: 2em;
    }

    .no-match-box a{
        font-size: 1.5em;
        width: 230px;
        border-radius: 30px;
    }
}

