.frprog-franchise-box{
    text-align: center;
    margin-top: 2.5em;
}

.frprog-h4{
    font-size: 1.5em;
    font-weight: 700;
}

.frprog-franchise-box span{
    font-weight: 700;
    color: #900018;
    font-size: 1.2em;
}

.frprog-box-list ul{
    list-style: none;
}

.frprog-box-list ul li::before{
    content: "\2022"; 
    color: #900018; 
    font-weight: bold; 
    display: inline-block; 
    width: 1em; 
    margin-left: -1em; 
}

.frprog-box-list ul li{
    font-size: 1em;
    transition: all 0.5s;
}

.frprog-box-list ul li:hover{
    transform: translateX(20px);
}