.about-cont{
    padding-top: 3em;
}

.about-card-cont{
    padding: 2em 0em;
}

.about-t h2{
    color: #C00000;
}

.about-t h2 span{
    color: #000231;
}



@media(min-width:992px){
    .about-p{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0 auto;
    }

    .pg{
        width: 100%;
        padding: 1em 1em 0;
    }

    .cont-img-info{
        min-height: 224px;
    }

    .cont-img-info div h3 {
        font-size: 1.5em;
    }

    .cont-img-info div p {
        font-size: 1.1em;
    }
}

@media(min-width:1200px){
    .about-cont{
        display: flex;
        padding-top: 5em;
    }

    .about-card-cont{
        padding: 0;
    }

    .pg-1{
        padding-left: 0;
    }
}


