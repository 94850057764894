/*
.carousel-cont{
    margin-top: 3.7em;
}
*/

.carousel-control-next, .carousel-control-prev{
    width: 5%!important;
}

.carousel-indicators{
    margin-bottom: 0!important;
}

.leave-message-btn, .franchise-btn, .register-btn{
    position: absolute;
    display: block;
    font-size: 0.4rem;
    font-weight: 600;
    background: #fff;
    padding: 0.2em;
    border: 2px solid #fff!important;
    letter-spacing: 1px;
    border-radius: 6px;
    transition: all 0.3s ease;
}

.franchise-btn{
    right: 0;
    transform: translate(-10%, -60%);
    color: #060276!important;
    width: 70px;
}

.leave-message-btn{
    top: 0;
    transform: translate(180%, -10%);
    color: #F69BB8!important;
    width: 80px;
}

.register-btn{
    top: 0;
    transform: translate(220%, -10%);
    color: #0F2A68!important;
    width: 70px;
}

.franchise-btn:hover, .leave-message-btn:hover, .register-btn:hover{
    background: transparent;
    color: #fff!important;
}

@media(min-width:420px){
    .franchise-btn, .leave-message-btn, .register-btn{
        font-size: 0.5rem;
    }

    .franchise-btn{
        transform: translate(-10%, -80%);
        width: 80px;
    }

    .leave-message-btn{
        transform: translate(150%, -40%);
        width: 90px;
    }

    .register-btn{
        transform: translate(220%, -40%);
        width: 80px;
    }
}

@media(min-width:500px){
    .franchise-btn, .leave-message-btn, .register-btn{
        font-size: 0.6rem;
    }

    .franchise-btn{
        transform: translate(-10%, -120%);
        width: 95px;
    }

    .leave-message-btn{
        transform: translate(160%, -80%);
        width: 105px;
    }

    .register-btn{
        transform: translate(240%, -100%);
        width: 90px;
    }
}

@media(min-width:600px){
    .franchise-btn, .leave-message-btn, .register-btn{
        font-size: 0.8rem;
    }

    .franchise-btn{
        width: 125px;
    }

    .leave-message-btn{
        transform: translate(10%, 10%);
        width: 130px;
    }

    .register-btn{
        transform: translate(200%, -150%);
        width: 120px;
    }
}

@media(min-width:730px){
    .franchise-btn, .leave-message-btn, .register-btn{
        font-size: 1rem;
    }

    .franchise-btn{
        transform: translate(-10%, -170%);
        width: 140px;
    }

    .leave-message-btn{
        transform: translate(10%, -30%);
        width: 160px;
    }

    .register-btn{
        transform: translate(230%, -190%);
        width: 140px;
    }
}

@media(min-width:900px){
    .franchise-btn{
        transform: translate(-30%, -200%);
    }

    .leave-message-btn{
        transform: translate(30%, -60%);
    }

    .register-btn{
        transform: translate(280%, -230%);
        width: 140px;
    }
}

@media(min-width:1000px){
    franchise-btn, .leave-message-btn, .register-btn{
        font-size: 1.2rem;
    }

    .franchise-btn{
        transform: translate(-40%, -210%);
        font-size: 1.2rem;
        width: 165px;
    }

    .leave-message-btn{
        width: 185px;
    }

    .register-btn{
        transform: translate(260%, -240%);
        width: 170px;
    }
}

@media(min-width:1150px){
    .franchise-btn{
        transform: translate(-40%, -300%);
        font-size: 1.2rem;
        width: 165px;
    }

    .leave-message-btn{
        transform: translate(70%, -140%);
    }

    .register-btn{
        transform: translate(290%, -290%);
        width: 170px;
    }
}

@media(min-width:1280px){
    .franchise-btn{
        transform: translate(-60%, -310%);
    }

    .register-btn{
        transform: translate(310%, -340%);
        width: 170px;
    }
}

@media(min-width: 1520px){
    .franchise-btn, .leave-message-btn, .register-btn{
        font-size: 2rem;
    }

    .franchise-btn{
        transform: translate(-40%, -250%);
        width: 255px;
    }

    .leave-message-btn{
        transform: translate(40%, -130%);
        width: 300px;
    }

    .register-btn{
        transform: translate(280%, -340%);
        width: 265px;
    }
}
