.footer-main{
    background: #900018;
    color: #fff;
}

.footer-container{
    padding: 1em;
}

.footer-title{
    color: #fff;
    text-align: center;
    font-size: 1.2em;
}

.flex-cont{
    display: flex;
    flex-direction: column;
}

.fifty-cont{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: 1em;
}

.fifty-cont h5{
    width: 200px;
    text-align: start;
    margin: 0 auto 0.5em;
}

.cent{
    justify-content: center;
}

.cent-h2{
    font-weight: 700;
    font-size: 1.5em;
}

.cent-h3{
    font-weight: 600;
    font-size: 1.5em;
}

.cent-h4{
    font-size: 1em;
}

.address, .footer-link{
    width: 200px;
    margin: 0 auto;
    text-align: start;
}

.footer-link{
    list-style: none;
    padding: 0;
}

.footer-link li{
    margin: 0.5em 0;
}

.second-box{
    margin: 2em 0em;
}

@media(min-width:576px){
    .flex-box{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media(min-width:768px){
    .flex-cont{
        flex-direction: row;
    }
}

@media(min-width: 1200px){
    .footer-flex{
        display: flex;
    }

    .first-box{
        width: 50%;
        min-width: 240px;
    }

    .second-box{
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 5em 0 0;
    }

    .flex-box{
        display: flex;
    }

    .footer-title{
        margin-top: 2.5em;
    }

    .cent{
        padding: 0;
    }

    .cent-1{
        width: 107px;
    }

    .cent-2{
        width: 140px;
    }

    .cent-3{
        width: 145px;
    }

    .cent-4{
        width: 175px;
    }
}

@media(min-width:1400px){
    .footer-container{
        display: flex;
        flex-direction: row;
    }

    .flex-box{
        flex-direction: column;

    }

    .footer-title{
        margin-top: 3.5em;
        margin-bottom: 0;
    }

    .footer-container{
        max-width: 1320px;
        justify-content: center;
    }

    .first-box{
        min-width: 249px!important;
        transform: translate(35px, 0);
    }

    .yt-footer-title{
        font-size: 1em;
        transform: translate(-40px, 50px);
    }

    .yt-footer{
        transform: translate(-90px, 60px);
    }

    .cent{
        padding: 0;
        width: 150px
    }

    .cent{
        margin: 1em;
    }

    .cent-1{
        width: 110px;
    }

    .cent-2{
        transform: translateX(20px);
    }

    .cent-4{
        width: 152px;
    }
}