.expo-title{
    margin-bottom: 0;
    color: #900018;
}

.expo-span{
    color: #010231;
}

.expo-date {
    font-size: 0.8em;
    font-weight: 600;
}

.expo-location {
    font-size: 1.3em;
    font-weight: 700;
    color: #010231;
}

.expo-img{
    width: 100%;
    margin: 0.5em 0 2em;
    padding-bottom: 1.5em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.219);
}

.news-and-event-head img{
    width: 100%;
    margin: 1em 0;
}

.news-and-event-head h4{
    margin-bottom: 0;
}

.news-and-event-head h4, .news-and-event-head p, .news-and-event-content span{
    font-weight: 700;
}

.news-and-event-head p{
    margin: 0;
}

.news-and-event-content h2, .news-and-event-head p{
    color: #900018;
}

.news-and-event-content h2{
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    padding-top: 1em;
}

.pub-margin{
    margin: 0;
}

.news-and-event-content a{
    color: #010231!important;
    display: inline-block;
    transition: all 0.3s;
}

.news-and-event-content a:hover{
    color: #900018!important;
}

.social-med p{
    font-weight: 500;
    margin-top: 2em;
}

.social-med span{
    color: #2C77F2;
    font-weight: 700;
}

.social-med-btn{
    color: #900018!important;
    padding-left: 0.5em;
    font-weight: 700!important;
    margin: 0!important;
    cursor: pointer;
}

.social-med-btn:hover{
    color: #2C77F2!important;
}

.prev-post-btn, .next-post-btn{
    margin-top:1em;
    display: flex;
    justify-content: flex-end;
}

.prev-post-btn a, .next-post-btn a{
    color: #900018!important;
    padding: 0.5em 1.5em;
    font-size: 1em;
    border: 2px solid #900018;
    border-radius: 20px;
    font-weight: 700;
}

.prev-post-btn a:hover, .next-post-btn a:hover{
    background: #900018!important;
    color: #fff !important;
}

.other-post-subcont{
    padding: 0 1em;
    margin-bottom: 2em;
    height: 500px;
    overflow: scroll;
    -ms-overflow-style: none;
     scrollbar-width: none;
}

.other-post-subcont::-webkit-scrollbar{
    display: none;
}

.other-post-cont h3{
    font-size: 1.8em;
    font-weight: 900;
    margin: 1em 0 0.5em 0.5em;
}

.other-post-box{
    display: flex;
    justify-content: space-between;
    padding: 1em 0;
}

.select-active{
    background-color: #F1F9FF;
}

.other-post-box img{
    width: 40%;
    height: 100px;
    object-fit: cover;
}

.other-post-box div{
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.other-post-box:not(:last-child){
    border-bottom: 1px solid rgba(0, 0, 0, 0.349);
}

.other-post-box div a{
    color: rgb(0, 0, 0) !important;
    font-size: 1.2em;
    transition: all 0.3s;
}

.other-post-box div a:hover{
    color: #900018!important;
}

.carousel-img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.expo-socmed{
    width: 100%;
    height: 760px;
    border: none;
    margin: 1.5em 0 0;
}

.socmed-box{
    margin: 0 auto;
}

.left-right-btn{
    display: flex;
    justify-content: space-between;
}

@media (min-width: 768px) {
    .other-post-subcont{
        width: 450px;
        margin: 0 auto;
        margin-bottom: 2em;
    }

    .other-post-cont h3{
        margin: 1em 0 0.5em 5em;
    }

    .other-post-box img{
        height: 105px;
    }

    .carousel-img{
        height: 500px;
    }

    .socmed-box{
        width: 555px;
    }

    .other-post-box div a{
        font-size: 1em;
    }
}

@media (min-width: 992px){
    .other-post-subcont{
        width: 100%;
    }

    .nae-content-head{
        margin: 0.2em 0 2em;
    }

    .nae-flex{
        display: flex;
        margin-top: 0.3em;
    }

    .nae-content-head{
        width: 80%;
    }

    .other-post-cont{
        width: 35%;
        margin-top: 0.5em;
    }

    .other-post-cont h3{
        margin: 0.2em 0 0.2em 0.5em;
    }

    .other-post-box img{
        width: 40%;
        height: 70px;
    }

    .other-post-box div a{
        font-size: 0.8em;
    }

    .other-post-box div p{
        font-size: 0.7em;
    }
}

@media(min-width:1200px){
    .other-post-box img{
        height: 85px;
    }

    .other-post-box div a{
        font-size: 0.9em;
    }
}

@media(min-width:1400px){
    .other-post-cont{
        width: 30%;
    }
}