.secondfooter-main{
    background: #000231;
}

.secondfooter-cont{
    color: #fff;
    text-align: center;
    padding: 0.3em 0 0.1em;
}

.secondfooter-icon i{
    margin: 1.5em 0.5em 0.5em;
    cursor: pointer;
    font-size: 0.8em;
    transition: all 0.3s
}

.fb-icon:hover{
    color: #2C77F2;
    transform: scale(1.5);
}

.yt-icon:hover{
    color: #F40010;
    transform: scale(1.5);
}

.arr-text{
    font-size: 0.7em;
}
