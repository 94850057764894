*::before,
*::after{
    box-sizing: border-box;
}

.franchise-cont{
    line-height: 1.6;
    padding: 4em 0 0!important;
    /*
    border: 1px solid #bbbbbb87;
    border-radius: 6px;
    box-shadow: 3px 3px 18px #0e0e0e6b;
    */
}

.fr-cont-title{
    text-align: center;
    color: #900018;
}

.fr-cont-title .title-navy{
    color: #000231;
}

.pre-op-su, 
.op-su,
.co-su,
.ma-su,
.fr-tr{
    background-size: cover;
    background-position: center;
    padding: 1rem 0 0;
    color: #fff;
    overflow: hidden;
}

.fr-card{
    margin: 1.5em auto;
    width: 100%;
    max-width: 330px;
    transition: all 0.3s ease; 
    border-radius: 3px;
}

.fr-card:hover,
.fr-card:focus-within{
    transform: scale(1.05);
    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);
}

.pre-op-su{
    background-image: url(../img/santa-maria-14.jpg);
}

.op-su{
    background-image: url(../img/opening.jpg);
}

.co-su{
    background-image: url(../img/continuing-support.jpg);
}

.ma-su{
    background-image: url(../img/marketing.jpg);
}

.fr-tr{
    background-image: url(../img/franchise.jpg);
}

.card-content{
    --padding: 1.5rem;
    padding: var(--padding);
    background: linear-gradient(
        hsl(0 0% 0% / 0), 
        hsl(0 0% 0% / 0.2) 20%,
        hsl(0 0% 0% / 1)
    );
}

.fr-title{
    position: relative;
    height: 35px;
    width: max-content;
    font-size: 1.2em;
    font-weight: 700;
}

.fr-title::after{
    content: "";
    position: absolute;
    height: 4px;
    width: calc(100% + var(--padding));
    left: calc(var(--padding) * -1);
    bottom: 0;
    background: #900018;
    transform-origin: left;
    transition: transform 500ms ease;
}

.fr-card:hover .fr-title::after,
.fr-card:focus-within .fr-title::after{
    transform: scaleX(1);
}

.fr-body{
    color: rgb(255 255 255 / 0.8);
}

.fr-btn{
    display: inline-block;
    background-color: #900018;
    color: #fff!important;
    padding: 0.5em 1.25em;
    border-radius: 0.25em;
}

.fr-btn:hover,
.fr-btn:focus{
    background-color: #ca0022;
}

@media (hover) {
    .card-content{
        transform: translateY(70%);
        transition: transform 500ms ease;
    }

    .fr-card:hover .card-content,
    .fr-card:focus-within .card-content{
    transform: translateY(0);
    transition-delay: 500ms;
    }

    .fr-card:focus-within .card-content{
    transition-duration: 0ms;
    }   

    .card-content > *:not(.fr-title) {
    opacity: 0;
    transition: opacity 500ms linear;
    }

    .fr-card:hover .card-content > *:not(.fr-title),
    .fr-card:focus-within .card-content > *:not(.fr-title) {
    opacity: 1;
    transition-delay: 1000ms;
    }

    .fr-title::after{
        transform: scaleX(0);
    }
}

@media(min-width: 768px){
    .fr-cont-in{
        display: flex;
        justify-content: space-between;
    }
}

@media(min-width:992px){
    .fr-cont-in{
        display: flex;
        flex-direction: column;
    }

    .franchise-cont{
        padding: 0;
    }

    .in-fr-cont{
        display: flex;
    }

    .in-cont-2{
        width: 67%;
        margin: 0 auto;
    }

    .fr-card{
        max-width: 305px;
    }
}

@media(min-width:1200px){
    .fr-card{
        max-width: 335px;
    }

    .franchise-cont{
        padding: 0!important;
        /*
        border: 1px solid #bbbbbb87;
        border-radius: 6px;
        box-shadow: 3px 3px 18px #0e0e0e6b;
        */
    }
}

@media(min-width:1400px){
    .fr-card{
        max-width: 335px;
    }

    .in-fr-cont{
        width: 87%;
        margin: 0 auto;
    }

    .in-cont-2{
        width: 58%;
    }
}