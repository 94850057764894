.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  @media(min-width: 1200px) {
    .video-responsive iframe {
      left: 85px;
      top: 0;
      max-height: 71%;
      max-width: 71%;
  }
}