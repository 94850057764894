.modal-header{
    border-bottom: none;
}

.modal-title{
    font-size: 1.5em;
    font-weight: 900;
    color: #900018;
}

.mapouter{
    margin-top: 1.5em;
}

.modal-info-cont{
    padding: 0 1em 1em;
}

.modal-info-cont div{
    width: 100%;
    padding-bottom: 0.5em;
}

.modal-divider{
    border-bottom: 1px solid #90001848;
}

.modal-info-cont p{
    font-weight: 700;
}

.modal-info-cont p span{
    font-weight: 400;
}

.iframe-map{
    width: 100%;
}

.modal-divider p{
    font-size: 14px;
    color: #900018;
}

.modal-divider p span {
    color: black;
}

.address-m{
    margin-bottom: 0;
}

.address-data{
    margin: 0;
    display: inline;
}

.email-m, .contact-m{
    margin: 1em 0 0;
}

.email, .contact{
    margin: 0;
}



/*
.main-img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin: 1em 0;
}

.cont-img{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
}
*/

.sub-cont-img{
    display: flex;
    width: 25%;
    margin: 0.2em;
}

/*
.sub-img{
    object-fit: cover;
    width: 100%;
    height: 80px;
}
*/

.img-w-100{
    height: 255px!important;
    object-fit: cover!important;
}

@media(min-width: 992px){
    .modal-body{
        display: flex;
    }

    .img-w-100{
        height: 225px!important;
    }

    .modal-info-cont{
        width: 50%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .img-modal-cont{
        width: 70%;
        padding: 0 0 0 1.5em;
    }

    /*
    .cont-img{
        padding-bottom: 0.5em;
    }
    */

    .main-img{
        margin: 0 0 1em 0;
    }

    .img-modal-cont{
        padding: 0 1.5em;
    }
}

@media(min-width: 1200px){
    .img-w-100{
        height: 370px!important;
    }

    .modal-info-cont{
        width: 40%;
    }
}