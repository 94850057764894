.navbar{
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.logo-img{
    width: 140px;
}

a{  
    display: flex;
    font-size: 0.9rem;
    font-weight: 600;
    text-decoration: none!important;
    align-items: center;
    color: rgba(255, 255, 255, 0.521)!important;
    transition: all 0.2s;
}

.link-active:hover, a:hover{
    color: #ffffff!important;
}

.link, .linking{
    margin-left: 1em;
}

.br{
    margin-top: 1em;
}

.dropdown-link{
    margin-left: 1em;
}

a.dropdown-item{
    font-size: 0.9rem;
    font-weight: 500;
}

.active{
    color: #ffffff!important;
}

.nav-link{
    display: flex!important;
    flex-direction: inherit!important;
}

.dropdown{
    display: none!important;
}

.btn-success, .droptes{
    background: transparent!important;
    border: none!important;
    color: rgba(255, 255, 255, 0.521)!important;
}

.droptes:hover{
    color: #fff!important;

}

.show>.btn-primary.dropdown-toggle{
    color: #fff!important;
}

.btn-primary:focus{
    color: #fff!important;
    box-shadow: none!important;
}

.dropdown-menu.show{
    background: #000231!important;
}

.dropdown-item:hover{
    color: #fff!important;
    background: #000231!important;
}

.btn:focus{
    box-shadow: none!important;
}

.navbar-toggler{
    font-size: 1rem!important;
    padding: .25rem .50rem!important;
}

.navbar-toggler-icon{
    width: 1em!important;
    height: 1em!important;
}

.bg-navyblue{
    background-color: #000231;
}

.dropdown-menu-dark{
    background-color: #000231!important;
}

.dropdown-item.active{
    background-color: #000231!important;
}

.franchise-now{
    margin-right: 4em;
    background: #bd001f;
    padding: 5px 15px;
    border-radius: 30px;
    border: 2px solid #bd001f;
    transition: 0.3s ease-out;
    color: #fff !important;;
}

.franchise-now:hover,
.franchise-now:focus-within{
    background: transparent;
    border: 2px solid #fff;
    color: #fff!important;
}


@media(max-width: 991px){
    .link{
        margin-bottom: 1em;
    }

    .linking{
        margin-top: 1em;
    }

    .franchise-now{
        margin: 0 auto;
        margin-bottom: 1em;
        margin-top: 0.5em;
    }

    .dropdown-link{
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    .border-icon{
        border-top: 1px solid rgba(255, 255, 255, 0.13);
        padding-top: 5px;
    }

    .last{
        flex-direction: row!important;
        justify-content: center;
    }

    .fab{
        margin: 0 0.5em;
    }

}

@media(min-width:992px){
    .navbar-expand-lg .navbar-nav .dropdown-menu{
        top: 30px;
    }

    .br{
        margin-left: 0.4em;
    }

    .dropdown{
        display: flex!important;
    }

    .franchise-now{
        margin-right: 2em;
    }

    .mobile{
        display: none;
    }

    .br{
        margin-top: 0;
    }
}






