.list-test{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5em 2em;
    margin: 2.8em auto;
    line-height: 2.5em;
    max-width: 1150px;
    color: #fff;
    border-radius: 6px;
    transition: all 0.3s;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.712);
}

.list-test:hover{
    transform: scale(1.05);
}

.list-test:nth-of-type(even){
    background: #000231;
}

.list-test:nth-of-type(odd){
    background: #900018;
}

.list-test img{
    width: 150px;
}

.list-test p{
    margin: 1.5em 0;
    font-size: 1.2em;
    font-weight: 500;
    color: rgb(202, 200, 200);
}

.list-test h3{
    font-size: 1.2em;
    font-weight: 500;
}