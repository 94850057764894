.fr-sec-cont{
    padding: 1em;
}

.franchising-cont{
    padding-top: 2em;
}

@media(min-width:992px){
    .fr-flex-cont{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .fr-sec-cont{
        width: 50%;
    }

    .franchising-cont-img{
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}