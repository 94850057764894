.fr-main-cont{
    width: 100%;
    padding-top: 1em;
}

.fr-main-h2, .fr-main-h3{
    text-align: center;
}

.fr-main-h2{
    color: #900018;
    margin-bottom: 1em;
}

.second-cont{
    padding: 1em;
}

ul li{
    font-size:1.2em;
    margin-bottom: 0.5em;
}

.franchising-img{
    width: 100%;
}

@media(min-width:992px){
    .fr-main-cont{
        display:flex;
        flex-direction: column;
    }

    .second-cont-main{
        width: 100%;
    }
    
    .first-cont{
        padding: 0 1em 0 0;
        width: 100%;
    }

    .img-cardgtl{
        object-fit: cover
    }
}

@media(min-width:1200px){
    .fr-main-cont{
        flex-direction: row;
    }

    .first-cont{
        transform: translateX(-2em);
        padding: 0 1em 0 0;
        width: 60%;

    }

    .second-cont-main{
        width: 40%;
    }
}