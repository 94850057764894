.card-cont{
    padding-top: 5em;
}

.card-cont-tpg{
    background:#000231;
    color: #fff;
    width: 100%;
    margin: 0 auto;
}

.card-pg .bold-p{
    font-weight: 700;
}

.last-pg{
    margin-bottom: 0;
}

.card-cont-img{
    background: #900018;
}

.img-pres{
    width: 100%;
}

.cont-pres-info{
    color: #fff;
    padding: 1.2em 0;
}

.cont-name-tp{
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.507);
    padding: 1.3em 0;
    width: 90%;
    margin: 0 auto;
}

.cont-name-tp h2{
    font-size: 1.2em;
    font-weight: 500;
}

.cont-name-tp h4{
    font-size: 0.9em;
    color: rgba(255, 255, 255, 0.568)!important;
    margin-bottom: 0!important;
}

.read-more{
    width: 106px;
    text-align: center;
    margin: 1em auto 0;
    padding: 0.5em 1em;
    box-shadow: inset 0 0 0 0 rgb(0, 2, 49);
    color: rgba(255, 255, 255, 0.507)!important;
    transition: 0.3s ease-out;
}

.read-more:hover,
.read-more:focus-within{
    color: #fff!important;
    box-shadow: inset 150px 0 0 0 rgb(0, 2, 49);
}

@media(min-width:768px){
    .card-cont{
        display: flex;
        flex-direction:row-reverse;
    }

    .card-cont-pg{
        padding: 1.5em;
    }
}

@media(min-width:992px){
    .card-cont-pg{
        padding: 3em 3em 0;
    }  
}

@media(min-width:1200px){
    .card-t{
        margin-bottom: 2em;
    }

    .card-p{
        margin-bottom: 2em;
    }
}

@media(min-width:1400px){
    .card-t{
        margin-bottom: 3em;
    }

    .card-p{
        font-size: 1.1rem;
    }
}