.newsevent-cont{
    margin-bottom: 3em;
}

.newsevent-title{
    text-align: center;
    color: #900018;
    margin: 1em 0;
}

.newsevent-flex{
    display: flex;
    flex-direction: column-reverse;
}

.newsevent-first{
    width: 80%;
    overflow: scroll;
    height: 450px;
    margin: 0 auto;
}

.newsevent-first::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.newsevent-first {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.newsevent-box{
    border: 1px solid #fff;
    padding: 1em;
    background-color: #F1F9FF;
}

.newsevent-date{
    font-size: 0.8em;
    color: #900018;
}

.newsevent-link{
    color: #000000!important;
}

.newsevent-link:hover{
    color: #900018!important;
}

.newsevent-link-title{
    font-size: 1.5em;
}

.newsevent-date, .latest-date{
    font-weight: 700;
}

.latest-box{
    width: 80%;
    margin: 0 auto 2em;
    transition: all 0.3s;
    background: #900018;
}

.latest-box:hover{
    transform: scale(1.05);
    background: #010231;
}

.latest-date{
    position: absolute;
    z-index: 1;
    color: #fff;
    background: #900018;
    padding: 0.5em;
}

.latest-img{
    position: relative;
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.latest-title-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    text-align: center;
    background-color: transparent;
    color: #fff;
    height: 110px;
}

.latest-link{
    color: rgb(255, 255, 255)!important;
}

.latest-link{
    transition: all 0.2s;
}

.latest-title-box .latest-link:hover{
    text-decoration: none!important;
}

.latest-title{
    font-weight: 700;
    font-size: 1.3em;
    margin-bottom: 0.5em;
}

@media (min-width: 500px){
    .latest-img{
        height: 300px;
    }
}

@media (min-width: 768px){
    .newsevent-flex{
        flex-direction: row-reverse;
    }

    .newsevent-first{
        width: 70%;
        height: 660px;
    }

    .latest-box{
        margin: 0 auto 2em;
        width: 90%;
    }

    .latest-img{
        height: 200px;
    }
}

@media (min-width: 1200px){
    .newsevent-first{
        width: 40%;
    }

    .latest-cont{
        display: flex;
        height: 100%;
        justify-content: space-evenly;
    }

    .latest-box{
        width: 45%;
        margin: 0;
    }
}
  

