.fr3rd-cont{
    padding: 2em 0;
}

.fr3rd-title{
    font-weight: 700;
    color: #900018;
}

.fr3rd-subtitle{
    font-weight: 700;
    font-size: 1.2em;
}

.fr3rd-sub{
    font-size: 1em;
    font-weight: 600;
}

.accordion-button:not(.collapsed) {
    color: #fff;
    background-color: #900018;
}

.accordion-button:focus {
    box-shadow: 0 0 0 0.10rem #900018bd; 
}

.accordion-button:not(.collapsed)::after {
    background-image: none !important;
}

.accordion-button::after {
    background-image: none !important;
}