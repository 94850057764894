.franchise-img{
    align-items: center;
}

.franchise-corp-img{
    max-width: 450px;
}

.franchise-logo-img{
    max-width: 350px;
}

@media(min-width: 992px){
    .franchise-content{
        width: 60%;
    }

    .franchise-img{
        width: 40%;
        align-items: flex-start;
    }

    .franchise-logo-img{
        max-width: 300px;
    }
}