.about-container, 
.company-container,
.franchise-container,
.criteria-container,
.success-container,
.growth-container,
.blessed-container{
    padding: 1.5em 0;
    border-bottom: 1px solid #e2e9ed;
}

.blessed-container,
.franchise-img,
.company-container,
.criteria-container,
.growth-container,
.about-pres-cont{
    display: flex;
}

.blessed-container, 
.franchise-img{
    flex-direction: column;
}

.company-container,
.criteria-container,
.growth-container{
    flex-direction: column-reverse;
}

.m5-title{
    margin-bottom: 1em;
}

.info-title,
.pres-bold,
.company-title,
.criteria-title,
.growth-title{
    color: #000231;
}

.info-span,
.franchise-title, 
.success-title, 
.blessed-title,
.company-title span{
    color: #900018;
}

.blessed-img img,
.company-img,
.criteria-img img,
.franchise-corp-img, .franchise-logo-img,
.growth-img img,
.success-img img,
.mv-content{
    width: 100%;
}

.blessed-img img, 
.criteria-img img,
.franchise-corp-img, .franchise-logo-img,
.growth-img img,
.success-img img{
    margin: 1em 0;
}

@media (min-width: 992px){
    .about-container,
    .blessed-container,
    .franchise-container,
    .mv-container,
    .success-container {
        display: flex;
    }

    .blessed-container,
    .company-container,
    .criteria-container,
    .growth-container,
    .mv-container{
        flex-direction: row;
    }

    .about-pres-cont,
    .blessed-img,
    .franchise-img,
    .success-img{
        padding-left: 2em;
    }

    .company-box,
    .criteria-img,
    .growth-img{
        padding-right: 2em;
    }

    .blessed-content,
    .growth-content,
    .success-content{
        width: 100%;
    }

    .blessed-img,
    .success-img,
    .growth-img,
    .criteria-img img{
        max-width: 400px;
    }
}