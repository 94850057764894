.company-img{
    margin: 1em 0 2em;
}

.yt-2{
    transform: translateY(2em);
}

@media (min-width: 992px){
    .company-img{
        width: 400px;
    }
}

@media (min-width: 1200px){
    .company-box{
        width: 425px;
        height: 800px;
    }

    .yt-2{
        transform: translate(-15%, -25%)!important;
    }

    .company-yt{
        width: 565px;
        transform: translate(-85px, -10px);
    }
}


@media (min-width: 1200px){
    .company-box{
        width: 425px;
        height: 750px;
    }
}