.mv-bg{
    background-color: #000231;
}

.mv-content{
    color: #fff;
    padding: 1.5em;
}

.mv-title{
    font-weight: 700;
    font-size: 2em;
    margin-bottom: 0.5em;
}

.mv-bold{
    font-weight: 700;
}

@media(min-width: 992px){
    .mv-content{
        margin: 3em 0;
    }
}