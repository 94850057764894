.branches-title{
    text-align: center;
    position: absolute;
    z-index: 10;
    padding: 0.5em;
    width: 50%;
    max-width: 250px;
    left: 10px;
    bottom: 0;
    background: rgb(255, 255, 255);
    box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.336);
    border-radius: 6px;
}

.branches-title h2{
    font-size:13px;
    margin: 0;
    font-weight: 700;
}

.branches-title h1{
    font-size:15px;
    font-weight: 900;
    color: #900018;
}

.branches-title .br-red{
    color: #900018;
}

.branches-title p{
    font-size: 7px;
    font-weight: 600;
    width: 100%;
    margin: 0 auto;
}

.box-cont-marker{
    width: 100%;
}

.box-cont-marker h2{
    font-size: 20px;
    font-weight: 900;
    color: #900018;
    margin: 0;
}

.box-address{
    font-size: 12px;
    font-weight: 700;
    color: #000231;
}

.box-flex-marker{
    display: flex;
}

.box-contact, .box-enail {
    width: 50%;
}

.box-contact h5, .box-email h5{
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 1px;
    color: #000231;
}

.box-contact p, .box-email p{
    font-size: 10px;
    font-weight: 700;
    color: #900018;
    margin: 0;
}

@media(min-width:992px){
    .branches-title{
        max-width: 400px;
        height: 110px;
        bottom: 0;
    }
    
    .branches-title h2{
        font-size: 20px;
    }
    
    .branches-title h1{
        font-size: 25px;
    }
    
    .branches-title p{
        font-size: 10px;
    }
}

@media(min-width: 1400px){
    .branches-title{
        bottom: 0px;
        max-width: 500px;
        height: 130px;
    }

    .branches-title h2{
        font-size: 25px;
    }
    
    .branches-title h1{
        font-size: 30px;
    }
    
    .branches-title p{
        font-size: 12px;
    }
}