.pres-bold{
    font-weight: 700;
}

.about-pres-cont{
    justify-content: center;
}

.about-pres{
    width: 350px;
}

@media(max-width: 576px){
    .about-pres{
        width: 100%;
    }
}

@media(min-width: 992px){
    .about-pres{
        height: 427px;
    }
}