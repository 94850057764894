.sidebar-cont{
    padding-top: 2em;
}

.sidebar-box{
    background: #900018;
    padding: 1em;
}

.fr-link{
    padding: 0.5em;
    border-bottom: 1px solid rgba(255, 255, 255, 0.459);
    font-size: 1.2em;
    letter-spacing: 2px;
}

.fr-link:last-child{
    border-bottom: 0;
}

.sidebar-contact-box{
    border: 1px solid rgba(61, 61, 61, 0.151);
    padding: 0.5em 1em;
    margin: 2em 0;
    box-shadow: 3px 3px 5px rgba(139, 139, 139, 0.582);
}

.sidebar-contact-flex{
    display: flex;
    align-items: center;
    padding: 0.5em;
}

.fr-icon{
    color: #900018;
    min-width: 25px;
}

.sidebar-contact-flex p{
    margin-bottom: 0;
    margin-left: 1em;
    font-weight: 600;
}

@media(min-width:992px){
    .sidebar-cont{
        width: 100%;
        max-width: 300px;
    }
}