.cont-img-info{
    margin-bottom: 2em;
}

.cont-img-info div{
    background: #000231;
    padding: 1em 2em;
    min-height: 200px;
}

.cont-img-info div h3{
    color: #C00000;
    text-align: center;
    font-weight: 800;
    margin-bottom: 1em;
}

.cont-img-info div p{
    color: #fff;
}

.img-cardgtl{
    width: 100%;
}

@media(min-width:768px){
    .cont-img-info{
        display: flex;
    }

    .cont-img-info div{
        min-height: 252px;
    }

    .cont-img-info div h3{
        margin: 1em 0;
    }

    .img-cardgtl{
        width: 50%;
    }
}

@media(min-width:992px){
    .cont-img-info{
        min-height: 224px;
    }

    .cont-img-info div{
        min-height: 312px;
    }

    .cont-img-info div h3 {
        font-size: 1.8rem;
    }

    .cont-img-info div p {
        font-size: 1.2rem;
    }
}

@media(min-width:1200px){
    .cardgtl-img-cont{
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 2em;
    }

    .cont-img-info{
        display: flex;
        flex-direction: row;
    }

    .img-cardgtl{
        width: 50%;
        object-fit: cover;
    }

    .cont-img-info div{
        padding: 1em;
        min-height: 225px;
    }

    .cont-img-info div h3{
        margin: 0.5em 0;
        font-size: 1.2rem;
    }

    .cont-img-info div p {
        font-size: 0.9rem;
    }
}