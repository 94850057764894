.team-cont{
    padding: 5em 1em;
}

.team-box-1{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-title-main{
    text-align: center;
    color: #000231;
}

.team-title-main span{
    color: #900018;
}

.team-button{
    display: block;
    text-align: center;
    width: 100px;
    text-align: center;
    margin: 0 auto 2em;
    color: #900018!important;
    border-bottom: 2px solid transparent;
}

.team-button:hover{
    color: #900018!important;
    border-bottom: 2px solid #900018;
}

.team-card{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 0 0 1px #E2E9ED;
    padding: 60px 10px;
    position: relative;
    z-index: 0;
    transition: all 0.2s;
}

.team-card:hover{
    z-index: 1;
    box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.team-avatar{
    width: 200px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto 2em;
}

.team-avatar img{
    width: 200px;
}

.team-content{
    text-align: center;
    
}

.team-title{
    font-size: 1.3em;
    opacity: 1;
}

.team-post{
    font-weight: 600;
    color: #900018;
}

@media (hover){
    .team-title{
        opacity: 0;
        transform: translateY(-30px);
    }

    .team-card:hover .team-title{
        opacity: 1;
        transform: translateY(0);
        transition: all 0.9s;
    }
}

@media (min-width: 768px){
    .team-box-1{
        width: 500px;
        margin: 0 auto
    }

    .team-box-2{
        display: flex;
        justify-content: center;
    }

    .team-1-cont, .team-2-cont{
        width: 100%;
    }
}

@media (min-width: 1200px){
    .team-box-2{
        flex-direction: column;
    }
    .team-box-2 div{
        display: flex;
        flex-direction: row;
    }

    .team-card{
        display: flex!important;
        flex-direction: column!important;
        min-width: 0;
    }

    .team-content{
        display: flex;
        flex-direction: column!important;
    }
}