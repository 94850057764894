.main-team{
    margin-bottom: 5em;
}

.main-team-title{
    text-align: center;
    font-size: 3em;
    font-weight: 900;
    margin: 0.5em 0 0.5em;
    letter-spacing: 2px;
    color: #AC0020;
}

.main-team-title-2{
    margin-bottom: 0.5em;
    color: #010231;
}