.map-cont{
    padding-bottom: 2em;
}

.map-box{
    text-align: center;
}

.map-box h5{
    margin: 2.5em 0 1em;
    color: #900018;
}

.contact-map{
    width: 100%;
    height: 550px;
    border: 0;
}

@media (min-width: 992px){
    .map-cont{
        display: flex;
    }

    .contact-map{
        height: 550px;
    }

    .map-box{
        width: 50%;
    }
}